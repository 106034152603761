<template>
  <div class="container jumbotron">
    <h1>Load dataset from file</h1>

    <div class="dataset_form">
      <b-form>
        <b-form-group
          id="input-group-1"
          label="Dataset Name:"
          label-for="input-1"
          description="What we'll refer to the dataset as later. If you are loading multiple columns from a CSV file, this is the prefix that we'll use, i.e. "
        >
          <b-form-input
            id="input-1"
            v-model="form.dataset_name"
            required
            placeholder="MyDataset"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Category:"
          label-for="input-1"
          description="What type of dataset is this?"
        >
          <b-form-input
            id="input-1"
            v-model="form.category"
            required
            placeholder="MyCategory"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-4" label="Select file">
          <b-form-file
            v-model="form.file"
            :state="Boolean(form.file)"
            placeholder="Select your file"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Permissions"
          description="If it's not public, then only those in your organisation can use the dataset."
        >
          <b-form-checkbox v-model="form.is_public">
            Is this dataset public?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          label="What type of data is it?"
          description="Select the type of dataset. If your dataset doesn't fit these options, please let us know."
        >
          <b-form-select
            v-model="form.dataset_type"
            :options="dataset_types"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-address"
          label="Address Column Name:"
          label-for="input-address"
          description="What column has the address in it?"
          v-if="form.dataset_type === 'csv-address'"
        >
          <b-form-input
            id="input-address"
            v-model="form.address_column_name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-csv-region-col"
          label="Region Identifier Column Name:"
          label-for="input-csv-region-column"
          description="What column has the region identifier in it?"
          v-if="form.dataset_type === 'csv-region'"
        >
          <b-form-input
            id="input-address"
            v-model="form.region_identifier_column"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-csv-region-type"
          label="Region Identifier Type:"
          label-for="input-address"
          description="What type of region is listed?"
          v-if="form.dataset_type === 'csv-region'"
        >
          <b-form-select
            id="input-csv-region-type"
            v-model="form.region_type"
            :options="valid_region_types"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-latlon"
          label="Latitude and longitude column names:"
          label-for="input-latlon"
          description="What columns have the latitude and longitude values?"
          v-if="form.dataset_type === 'csv-latlon'"
        >
          <b-form-input
            id="input-lat"
            v-model="form.latitude"
            required
          ></b-form-input>
          <b-form-input
            id="input-lon"
            v-model="form.longitude"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-latlon"
          label="Data columns:"
          label-for="input-latlon"
          description="What columns have the data you want? Each column forms a dataset. Hit enter after typing the column name."
          v-if="form.dataset_type.startsWith('csv')"
        >
          <b-form-tags
            input-id="tags-basic"
            v-model="form.column_names"
            class="mb-2"
            remove-on-delete
            tag-variant="primary"
          ></b-form-tags>
        </b-form-group>
        <b-form-group
          id="skiprows-group"
          label="Skip Rows:"
          label-for="skiprows-input"
          description="Skips the first few rows (0 to start reading from the top). Do not skip the header row!"
        >
          <b-form-input
            id="skiprows-input"
            type="number"
            v-model="form.skiprows"
            min="0"
            required
            placeholder="0"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </div>
    <div class="front_messaging">
      <p>
        For assistance on setting up new dashboards, please contact us through
        enquire@reboundary.com
      </p>
      <p>
        If you are uploading the same dataset lots of times, let us know and we
        can add a special upload button that has all these values preconfigured.
      </p>
      <p>
        If you choose a name for your dataset that already exists for your
        organisation, it will update the existing dataset.
      </p>
    </div>
    {{ form }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataset_types: [
        { value: 'poi', text: 'Points of interest in a GeoJSON file' },
        {
          value: 'csv-latlon',
          text: 'POI CSV file containing a latitude and longitude columns',
        },
        { value: 'csv-address', text: 'CSV file containing an address column' },
        {
          value: 'csv-region',
          text: 'CSV file containing a region identifier code (like SA1, Postcode or Suburb)',
        },
      ],
      valid_region_types: [
        { value: 'sa1', text: 'SA1 - ABS Statistical Area Level 1' },
        { value: 'sa2', text: 'SA2 - ABS Statistical Area Level 2' },
        { value: 'sa3', text: 'SA3 - ABS Statistical Area Level 3' },
        { value: 'sa1', text: 'SA1 - ABS Statistical Area Level 1' },
        { value: 'postcode', text: 'Postcode - Australia Post' },
        { value: 'suburb', text: 'Suburb names' },
      ],
      form: {
        is_public: false,
        address_column_name: 'Address',
        region_identifier_column: 'SA1',
        region_type: 'sa1',
        latitude: 'Latitude',
        longitude: 'Longitude',
        dataset_type: 'csv-region',
        skiprows: 0,
      },
    };
  },
};
</script>
